<template>
  <div class="bbs-main">
    <van-pull-refresh v-model="refreshing" success-text="刷新成功" @refresh="onRefresh">
      <van-list class="bbs-list" v-model="loading" :finished="finished" finished-text="没有更多了" @load="initList">
        <BBSList v-for="(item, index) in listData" :key="index" :item="item" />
      </van-list>
    </van-pull-refresh>
  </div>
</template>

<script>
import Http from '@/utils/Http.js';

import { Image as VanImage, List, PullRefresh } from 'vant';

import { handlerEror } from '@/utils/Tools';

import BBSList from '@/components/BBS/List.vue';

const MyCommunityList = 'cloud/ec/tally/getMyCommunityList';

export default {
  components: {
    BBSList,
    VanImage,
    vanList: List,
    vanPullRefresh: PullRefresh
  },

  data() {
    return {
      loading: false,
      finished: false,
      refreshing: false,
      pageData: {
        pageNum: 1,
        pageSize: 10
      },
      listData: []
    };
  },
  methods: {
    initList(isInit) {
      this.loading = true;

      Http.get(MyCommunityList, this.pageData)
        .then((data) => {
          this.loading = false;
          this.refreshing = false;

          if (data.object.length) {
            if (isInit) {
              this.listData = [];
            }
            this.listData.push(...data.object);
            this.pageData.pageNum++;
          } else {
            this.finished = true;
          }
        })
        .catch((err) => {
          this.finished = true;
          this.loading = false;
          handlerEror(err);
        });
    },
    onRefresh() {
      this.finished = false;
      this.pageData.pageNum = 1;

      this.initList(true);
    }
  }
};
</script>

<style lang="less" scoped></style>
